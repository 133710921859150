import React from "react"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Audit = ({ location }) => {
  const data = useStaticQuery(graphql`
    query ReviewsQuery {
      facebook: file(relativePath: { eq: "facebook-reviews.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      reviews: file(relativePath: { eq: "reviews-io.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      google: file(relativePath: { eq: "google-reviews.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <SEO
        titleOverride={"Feedback Reviews"}
        pathnameOverride={location.pathname}
      />

      <section className="mb-16 lg:mb-32">
        <div className="container">
          <div className="w-full md:w-2/3 text-center mx-auto mb-8 md:mb-0">
            <h1>Feedback Reviews</h1>
            <p className="text-lg mb-8 md:mb-16">We are glad that  you have had a positive experience with us. It would be great if you could help spread the word through one of our review platforms below (our preference would be Google Reviews if you have a Google Account already). Thank you.</p>
          </div>
        </div>
        <div className="w-full md:w-8/12 xl:w-1/2 mx-auto">
          <ul className="text-center md:flex md:flex-wrap justify-center">
            <li className="block mb-5 px-5">Google reviews<br/><a href="http://adtrak.co.uk/reviews" target="_blank" rel="noopener noreferrer" className="underline hover:opacity-75" aria-label="Open Google Reviews"><Img className="block w-40 mx-auto mt-2" fluid={data.google.childImageSharp.fluid} /></a></li>
            <li className="block mb-5 px-5">Facebook reviews<br/><a href="https://www.facebook.com/adtrak/reviews/" target="_blank" rel="noopener noreferrer" className="underline hover:opacity-75" aria-label="Open Facebook Reviews">
            <Img className="block w-12 mx-auto mt-2" fluid={data.facebook.childImageSharp.fluid} /></a></li>
            <li className="block mb-5 px-5">Reviews.io<br/><a href="https://www.reviews.co.uk/company-reviews/store/adtrak-llp" target="_blank" rel="noopener noreferrer" className="underline hover:opacity-75" aria-label="Open Reviews.io Reviews"><Img className="block w-32 mx-auto mt-2" fluid={data.reviews.childImageSharp.fluid} /></a></li>
          </ul>
        </div>
      </section>
    </>
  )
}

export default Audit